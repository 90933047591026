let onLoad = () => {
  const illustration = document.getElementById("illustration");
  illustration.style.visibility = "visible";
  let sheep = document.getElementsByClassName("sheep")[0];
  sheep.onanimationend = (e) => {
    e.target.classList.remove("grow");
  };

  const growableElements = document.querySelectorAll("#illustration > img[data-grow]");
  growableElements.forEach(element => {
    element.classList.add("grow");
  })

  new Parallax(illustration);
};
window.addEventListener('load', onLoad);
